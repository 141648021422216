import * as React from "react";
import Icon from "./icon";
import { useState } from "react";

export const InputTypes = [
  "search",
  "email",
  "text",
  "password",
  "tel",
] as const;

export interface InputProps {
  type: typeof InputTypes[number];
  name?: string;
  id?: string;
  placeholder: string;
  className?: string;
  initalValue?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  hideLabel?: boolean;
  label?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  name,
  id,
  placeholder,
  className,
  initalValue = "",
  onChange,
  onClear,
  hideLabel = true,
  label = "input",
}) => {
  const [inputValue, setInputValue] = useState(initalValue);

  return (
    <div className={`flex flex-col space-y-2  ${className}`}>
      {!hideLabel && (
        <label className={"font-bold"} htmlFor={id}>
          {label}
        </label>
      )}

      <div className={"relative"}>
        <input
          autoComplete={"off"}
          id={id}
          type={type}
          name={name}
          value={inputValue}
          onChange={ev => {
            setInputValue(ev.target.value);
            onChange(ev.target.value);
          }}
          required
          placeholder={placeholder}
          className={`py-2 rounded-md bg-white
          px-5 w-full
          border border-dark-100
          ring-dark-100/50
          focus:outline-none focus:ring-2 ${type === "search" && "pr-8"}`}
        />
        {type === "search" && inputValue.length > 0 && (
          <button
            className={"absolute right-0 top-0 bottom-0 p-2"}
            onClick={() => {
              setInputValue("");
              onClear();
            }}
          >
            <Icon icon={"close"} className={"h-5"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
