import { graphql } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { debounce, linkWithSlash } from "../../lib/functions";
import { usePagination } from "../../lib/hooks";

// components
import Layout from "../../components/layout";
import LinkText from "../../components/link";
import Seo from "../../components/seo";
import Input from "../../components/custom-input";
import Pagination from "../../components/pagination";

// types & interfaces
import type { LexiqueTerm, PageProps, SEO } from "../../lib/types";

interface LexiquePageProps {
  SEO: SEO;
  title: string;
}

type LexiquePageData = {
  lexiques: {
    terms: Array<LexiqueTerm>;
  };
};

const LexiquePage: React.FC<PageProps<LexiquePageProps, LexiquePageData>> = ({
  data: {
    page: { SEO, title },
    lexiques: { terms },
  },
  location: { pathname },
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTerms =
    searchTerm.length > 0
      ? terms.filter(term =>
          term.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : terms;

  const {
    maxPage: numPages,
    currentPage: page,
    jump,
    currentData,
  } = usePagination({
    data: filteredTerms,
    perPage: 50,
  });

  const itemAnimationOptions = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <>
      <Layout active={'#infos'}>
        <Seo {...SEO} pathname={pathname} />
        <main className="mt-40 mb-20 flex flex-col items-center space-y-8 px-5 md:px-15">
          <h1 className="text-3xl font-bold text-center">{title}</h1>

          <Input
            className={"w-full lg:w-1/2 xxl:w-1/3"}
            type="search"
            name="search"
            id="search"
            placeholder="Rechercher un terme"
            initalValue={searchTerm}
            onClear={() => {
              setSearchTerm("");
            }}
            // @ts-ignore
            onChange={debounce((value: string) => {
              setSearchTerm(value.trim());
              jump(1);
            }, 300)}
          />

          <section
            className="grid gap-1 pb-24 w-full
            lg:grid-cols-3
            "
          >
            <AnimatePresence>
              {filteredTerms.length > 0 ? (
                currentData().map((term, index) => (
                  <motion.div
                    key={index}
                    variants={itemAnimationOptions}
                    initial={"hidden"}
                    animate={"show"}
                    exit={"hidden"}
                    transition={{
                      duration: 0.3,
                    }}
                  >
                    <LinkText
                      label={term.name}
                      className={"capitalize"}
                      href={linkWithSlash(`/lexique/${term.SEO.slug}`)}
                    />
                  </motion.div>
                ))
              ) : (
                <motion.div
                  key={"None"}
                  variants={itemAnimationOptions}
                  initial={"hidden"}
                  animate={"show"}
                  exit={"hidden"}
                  className={"col-span-3 text-center"}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  <span className={"capitalize"}>
                    Aucune entrée trouvée pour le terme &nbsp;
                    <span className={"font-bold italic"}>"{searchTerm}"</span>
                  </span>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Pagination */}
            <div className="flex lg:col-span-3 items-center justify-center mt-5">
              {numPages > 1 && (
                <Pagination
                  basePath={`/lexique/pages`}
                  numPages={numPages}
                  currentPage={page}
                  onPageChanged={page => jump(page)}
                />
              )}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default LexiquePage;

export const query = graphql`
  query pageLexique {
    page: strapiPageLexique {
      title
      SEO {
        description
        slug
        title
      }
    }
    lexiques: allStrapiLexique {
      terms: nodes {
        name: term
        SEO {
          slug
        }
      }
    }
  }
`;
