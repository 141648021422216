import * as React from "react";
import { Link } from "gatsby";

export interface PaginationProps {
  numPages: number;
  currentPage: number;
  onPageChanged?: (page: number) => void;
  basePath?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  numPages,
  currentPage,
  onPageChanged,
  basePath,
}) => {
  return (
    <div className={"border border-dark-100 inline-flex rounded-md"}>
      {!basePath ? (
        <>
          {/* Previous Button */}
          <button
            disabled={currentPage <= 1}
            onClick={() => onPageChanged(currentPage - 1)}
            className={`rounded-l-md hover:bg-light-50 p-3 border-r border-dark-100 ${
              currentPage <= 1 &&
              "bg-gray-400/20 cursor-not-allowed text-gray-500"
            }`}
            aria-label={"Aller à la Page Précédente"}
          >
            ‹
          </button>

          {/* Pages Buttons */}
          {Array(numPages)
            .fill(1)
            .map((_, index) => (
              <button
                onClick={() => onPageChanged(index + 1)}
                aria-label={
                  index + 1 !== currentPage
                    ? `Aller à la Page ${index + 1}`
                    : `Page Actuelle`
                }
                className={`border-dark-100 p-3 font-bold  ${
                  index + 1 === currentPage
                    ? "bg-secondary-100 text-white "
                    : "hover:bg-light-50"
                } ${index !== 0 && "border-l"}
             ring-dark-100/50
            focus:outline-none focus:ring-2
            `}
              >
                <span>{index + 1}</span>
              </button>
            ))}

          {/* Next Button */}
          <button
            disabled={currentPage >= numPages}
            onClick={() => onPageChanged(currentPage + 1)}
            className={`rounded-r-md  hover:bg-light-50 p-3 border-l border-dark-100 ${
              currentPage >= numPages &&
              "bg-gray-400/20 cursor-not-allowed text-gray-500"
            }`}
            aria-label={"Aller à la Page Suivante"}
          >
            ›
          </button>
        </>
      ) : (
        <>
          {/* Previous Button */}
          <Link
            onClick={e => {
              if (currentPage <= 1) {
                e.preventDefault();
              }
            }}
            className={`rounded-l-md hover:bg-light-50 p-3 border-r border-dark-100 ${
              currentPage <= 1 &&
              "bg-gray-400/20 cursor-not-allowed text-gray-500"
            }`}
            aria-label={"Aller à la Page Précédente"}
            to={`${basePath}/${
              currentPage == 1 ? currentPage : currentPage - 1
            }/`}
          >
            ‹
          </Link>

          {/* Pages Buttons */}
          {Array(numPages)
            .fill(1)
            .map((_, index) => (
              <Link
                to={`${basePath}/${index + 1}/`}
                aria-label={
                  index + 1 !== currentPage
                    ? `Aller à la Page ${index + 1}`
                    : `Page Actuelle`
                }
                className={`border-dark-100 p-3 font-bold  ${
                  index + 1 === currentPage
                    ? "bg-secondary-100 text-white "
                    : "hover:bg-light-50"
                } ${index !== 0 && "border-l"}
             ring-dark-100/50
            focus:outline-none focus:ring-2
            `}
              >
                <span>{index + 1}</span>
              </Link>
            ))}

          {/* Next Button */}
          <Link
            onClick={e => {
              if (currentPage >= numPages) {
                e.preventDefault();
              }
            }}
            className={`rounded-r-md  hover:bg-light-50 p-3 border-l border-dark-100 ${
              currentPage >= numPages &&
              "bg-gray-400/20 cursor-not-allowed text-gray-500"
            }`}
            aria-label={"Aller à la Page Suivante"}
            to={`${basePath}/${
              currentPage >= numPages ? numPages : currentPage + 1
            }/`}
          >
            ›
          </Link>
        </>
      )}
    </div>
  );
};

export default Pagination;
